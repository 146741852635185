import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService, NzModalTitleDirective } from 'ng-zorro-antd/modal';
import { ScrapReasonService } from 'src/app/private/services/scrap-reason.service';
import { ScrapService } from 'src/app/private/services/scrap.service';
import { ScrapCauseDto } from 'src/app/shared/dto/scrap-cause.dto';
import { ScrapReasonDto } from 'src/app/shared/dto/scrap-reason.dto';
import { ScrapDto } from 'src/app/shared/dto/scrap.dto';
import { NzFormControlComponent, NzFormDirective, NzFormItemComponent } from 'ng-zorro-antd/form';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';

@Component({
    selector: 'app-modal-create-scraps',
    templateUrl: './modal-create-scraps.component.html',
    styleUrls: ['./modal-create-scraps.component.scss'],
    standalone: true,
    imports: [
        NzModalTitleDirective,
        NzModalFooterDirective,
        ReactiveFormsModule,
        NzFormDirective,
        NzRowDirective,
        NzFormItemComponent,
        NzColDirective,
        NzFormControlComponent,
        NzInputDirective,
        NzSelectComponent,
        NzOptionComponent,
        NzMessageModule,
        NzFormModule
    ]
})
export class ModalCreateScrapsComponent implements OnInit {
    @Output() emitService = new EventEmitter();
    isLoading: boolean = true;
    processId: number = 0;
    processDescription: string = '';
    scrapReasons: ScrapReasonDto[] = [];
    scrapCauses: ScrapCauseDto[] | undefined = [];

    validateForm: FormGroup<{
        observations: FormControl<string>;
        quantity: FormControl<number>;
        selectedReason: FormControl<ScrapReasonDto>;
        selectedCause: FormControl<ScrapCauseDto>;
    }> = this.fb.group({
        observations: ['', [Validators.required]],
        quantity: [0, [Validators.required]],
        selectedReason: [new ScrapReasonDto(), [Validators.required]],
        selectedCause: [new ScrapCauseDto(), [Validators.required]],
    });

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private _modalService: NzModalService,
        private toast: NzMessageService,
        private _scrapsService: ScrapService,
        private _scrapReasonsService: ScrapReasonService,
        private fb: NonNullableFormBuilder,
        private userLoggedService: UserLoggedService
    ) {
        this.processId = data?.data?.processId as number;
        this.processDescription = data?.data?.processDescription as string;

        this.isLoading = false;
    }

    async ngOnInit() {
        await this.getScrapReasons();
    }

    async submitForm(): Promise<void> {
        try {
            console.log('submit', this.validateForm.value);

            if (!this.validateForm.valid) {
                this.toast.error(`Porfavor, rellene los campos obligatorios`);
                return;
            }

            const scrap: ScrapDto = {
                productionOrderProcessId: this.processId,
                scrapCauseId:
                    Number(this.validateForm.value.selectedCause) ?? 0,
                units: this.validateForm.value.quantity ?? 0,
                date: new Date(),
                observations: this.validateForm.value.observations ?? '',
                createdById: this.userLoggedService.userLogged.id,
            };

            const createdScrap: ScrapDto = await this._scrapsService.create(scrap);

            this.emitService.emit(createdScrap);
            this._modalService.closeAll();
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.toast.error(e.message);
            }
        }
    }

    loadCauses(reasonId: any): void {
        const scrapReason = this.scrapReasons.find(
            (reason) => reason.id === +reasonId
        );

        if (!scrapReason) {
            this.toast.error('No se han podido cargar las causas de tara');
            return;
        }

        this.scrapCauses = scrapReason.scrapCauses;
    }

    private async getScrapReasons(): Promise<void> {
        this.scrapReasons = await this._scrapReasonsService.findAll();
    }

    handleCancel() {
        this._modalService.closeAll();
    }
}
