import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective } from 'ng-zorro-antd/table';
import { NzTransferComponent, TransferDirection, TransferItem } from 'ng-zorro-antd/transfer';
import { Subject, takeUntil } from 'rxjs';
import { DrawerMode } from 'src/app/core/enums/drawer-mode';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ScreenService } from 'src/app/public/services/screen.service';
import { MachineDto } from 'src/app/shared/dto/machine.dto';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { DirectionsEnum } from 'src/app/shared/enums/directions.enum';
import { ItemFormStructure } from 'src/app/shared/services/form.service';
import { MachineService } from '../../services/machine.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-screens',
    templateUrl: './screens.component.html',
    styleUrl: './screens.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzButtonComponent,
        NzWaveDirective,
        ɵNzTransitionPatchDirective,
        NzPageHeaderContentDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        NzDividerComponent,
        NzMessageModule,
        ReactiveFormsModule,
        FormsModule,
        NzSelectModule,
        NzDrawerModule,
        NzTransferComponent
    ],
})
export class ScreensComponent implements OnInit, OnDestroy {

    routeActually: string = '';
    title: string = '';
    formStructure: ItemFormStructure[] = [];
    componentService: any;

    drawerVisible: boolean = false;
    drawerMode!: DrawerMode;
    drawerTitle: string = '';
    fieldsReadOnly: boolean = false;
    usersSelected: UserDto[] = [];
    machinesTransferItems: TransferItem[] = [];

    screens: ScreenDto[] = [];
    screenSelected!: ScreenDto;
    users: UserDto[] = [];
    userSelected!: UserDto;
    machines: MachineDto[] = [];
    machinesSelectedIds: number[] = [];

    loading: boolean = false;
    userName: string | undefined = '';
    userLogged: { name: string; rol: string; token: string } | null = null;

    form: FormGroup;

    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        public readonly screensService: ScreenService,
        private readonly fb: FormBuilder,
        private readonly userService: UserService,
        private readonly machineService: MachineService,
        private readonly toast: NzMessageService,
        private readonly router: Router,
        private readonly userLoggedService: UserLoggedService,
    ) {
        this.form = this.fb.group({
            name: ['', Validators.required],
            ip: ['', [Validators.required, Validators.pattern('(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
            users: [[]],
            machines: [[]],
        });
    }

    async ngOnInit(): Promise<void> {

        try {
            this.userName = this.userLoggedService.userLogged?.username;
            this.componentService = this.screensService;

            await this.screensService.setupWebSocket();
            this.screensService.screens$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (screens) => {
                        this.screens = screens;
                        this.screenSelected = this.screens.find((screen) => screen.id === this.screenSelected?.id) || new ScreenDto();
                        if (this.screenSelected.id) {
                            this.initTransferItemsComponent();
                        }
                    },
                    error: () => {
                        this.toast.error('Error cargando pantallas');
                    }
                });

            await this.userService.setupWebSocket();
            this.userService.users$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (users) => {
                        this.users = users;
                        this.usersSelected = this.users.filter((user) => this.usersSelected.some((u) => u.id === user.id));
                    },
                    error: () => {
                        this.toast.error('Error cargando usuarios');
                    }

                });

            await this.machineService.setupWebSocket();
            this.machineService.machines$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                    {
                        next: (machines) => {
                            this.machines = machines;
                        },
                        error: () => {
                            this.toast.error('Error cargando máquinas');
                        }
                    }
                );
            this.routeActually = this.router.url;
        } catch (err) {
            this.toast.error('Error cargando pantallas:');
        }
    }

    ngOnDestroy(): void {
        this.screensService.disconnectWebSocket();
        this.userService.disconnectWebSocket();
        this.machineService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openDrawer(): void {
        this.initTransferItemsComponent();
        this.drawerVisible = true;
    }

    closeDrawer(): void {
        this.machinesSelectedIds = [];
        this.machinesTransferItems = [];
        this.screenSelected = new ScreenDto();
        this.form.reset();
        this.drawerVisible = false;
    }

    async saveForm(): Promise<void> {
        this.includeSelectedMachinesInForm();

        if (this.form.valid) {
            const screenData: ScreenDto = this.form.value;
            try {
                if (this.drawerMode === DrawerMode.CREATE) {
                    const screenCreated = await this.screensService.create(screenData);
                    this.toast.success(`Pantalla ${screenCreated.name} creada`);
                } else if (this.drawerMode === DrawerMode.EDIT) {
                    const screenEdited = await this.screensService.updateScreen(this.screenSelected.id!, screenData);
                    this.toast.success(`Pantalla ${screenEdited.name} editada`);
                }
            } catch (error) {
                this.toast.error('Error guardando pantalla');
            }

            this.closeDrawer();
        } else {
            this.toast.error('El formulario contiene errores');
        }
    }

    includeSelectedMachinesInForm(): void {
        try {
            this.machinesSelectedIds = [];
            this.machinesTransferItems.forEach((element) => {
                if (element.direction === DirectionsEnum.RIGHT as TransferDirection) {
                    this.machinesSelectedIds.push(element['key']);
                }
            });

            const machinesSelected = this.machines.filter(machine => this.machinesSelectedIds.includes(machine.id));
            this.form.get('machines')?.setValue(machinesSelected);
        } catch (error) {
            this.toast.error('Error incluyendo máquinas seleccionadas en el formulario');
        }
    }

    cancelForm(): void {
        this.closeDrawer();
    }

    compareUserFn = (o1: UserDto, o2: UserDto): boolean => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };

    initTransferItemsComponent(): void {
        try {
            const transferItems: TransferItem[] = [];
            const availableMachines = this.machines.filter((machine) => {
                return !this.screenSelected.machines?.some((m) => m.id === machine.id);
            });

            availableMachines.forEach((availableMachine) => {
                transferItems.push({
                    key: availableMachine.id,
                    title: availableMachine.name,
                    description: availableMachine.ip,
                    direction: DirectionsEnum.LEFT as TransferDirection,
                });
            });

            this.screenSelected.machines?.forEach((assignedMachine) => {
                transferItems.push({
                    key: assignedMachine.id,
                    title: assignedMachine.name,
                    description: assignedMachine.ip,
                    direction: DirectionsEnum.RIGHT as TransferDirection,
                });
            });

            this.machinesTransferItems = transferItems;
        } catch (error) {
            this.toast.error('Error inicializando la transferencia de máquinas');
        }
    }

    async create(): Promise<void> {
        this.drawerTitle = 'Crear pantalla';
        this.drawerMode = DrawerMode.CREATE;
        this.fieldsReadOnly = false;
        this.openDrawer();
    }

    view(data: any): void {
        this.drawerTitle = 'Ver Pantalla';
        this.drawerMode = DrawerMode.VIEW;
        this.fieldsReadOnly = true;
        this.form.patchValue(data);
        this.screenSelected = data;
        this.openDrawer();
    }

    edit(data: any): void {
        this.drawerTitle = 'Editar pantalla';
        this.drawerMode = DrawerMode.EDIT;
        this.fieldsReadOnly = false;
        this.screenSelected = data;
        this.form.patchValue(data);
        this.openDrawer();
    }

    async delete(data: any): Promise<void> {
        if (window.confirm('¿Estás seguro de que quieres eliminar este artículo? Esta acción no se puede deshacer.')) {
            try {
                const screenDeleted = await this.screensService.deleteScreen(data.id);
                this.toast.success(`Pantalla ${screenDeleted.name} eliminada`);
            } catch (error) {
                this.toast.error('Error eliminando pantalla');
            }
        }
    }
}
