<div class="p-10 h-full">
    <nz-page-header
        class="text-[16px] font-semibold p-0 m-0"
        nzTitle="Pantallas"
    >
        <nz-page-header-extra>
            <button nz-button nzType="primary" (click)="create()">Nuevo</button>
        </nz-page-header-extra>
        <nz-page-header-content> </nz-page-header-content>
    </nz-page-header>

    <nz-table
        #nestedTable
        nzShowSizeChanger
        nzTableLayout="auto"
        [nzData]="screens"
        [nzLoading]="loading"
        [nzSimple]="true"
        nzTableLayout="auto"
        nzBordered
    >
        <thead>
            <tr>
                <th id="name">Nombre</th>
                <th id="ip">Dirección IP</th>
                <th id="actions">Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of nestedTable.data; track data) {
                <tr>
                    <td>{{ data.name }}</td>
                    <td>{{ data.ip }}</td>
                    <td>
                        <div class="buttonsDnDTableContainer">
                            <img
                                src="assets/icons/eye-icon.svg"
                                alt="show"
                                class="h-6 w-6"
                                (click)="view(data)"
                                title="Ver"
                            />
                            <nz-divider nzType="vertical"></nz-divider>
                            <img
                                src="assets/icons/edit-icon.svg"
                                alt="edit"
                                class="h-6 w-6"
                                (click)="edit(data)"
                                title="Editar"
                            />
                            <nz-divider nzType="vertical"></nz-divider>
                            <img
                                src="assets/icons/xmark-icon.svg"
                                alt="delete"
                                class="h-6 w-6"
                                (click)="delete(data)"
                                title="Eliminar"
                            />
                        </div>
                    </td>
                </tr>
            }
        </tbody>
    </nz-table>
</div>
<nz-drawer
    [nzClosable]="false"
    [nzVisible]="drawerVisible"
    nzPlacement="right"
    [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()"
    [nzWidth]="'500px'"
    [nzMaskClosable]="fieldsReadOnly"
>
    <ng-container *nzDrawerContent>
        <form [formGroup]="form" (ngSubmit)="saveForm()">
            <label for="name">Nombre:</label>
            <input
                nz-input
                type="text"
                class="searchSelectors"
                [readOnly]="fieldsReadOnly"
                id="name"
                formControlName="name"
            />
            <label for="ip">IP:</label>
            <input
                nz-input
                type="text"
                class="searchSelectors"
                [readOnly]="fieldsReadOnly"
                id="ip"
                formControlName="ip"
            />
            <label for="users">Usuarios:</label>
            <nz-select
                nzMode="multiple"
                class="searchSelectors"
                [nzDisabled]="fieldsReadOnly"
                id="users"
                [compareWith]="compareUserFn"
                formControlName="users"
            >
                @for (user of users; track user.id) {
                    <nz-option
                        [nzLabel]="user.name"
                        [nzValue]="user"
                    ></nz-option>
                }
            </nz-select>

             <label for="machines">Máquinas vinculadas:</label>
            <nz-transfer
                class="mb-5"
                [nzDataSource]="machinesTransferItems"
                [nzDisabled]="fieldsReadOnly"
                [nzTitles]="['Maq.Disponible', 'Maq.Actuales']"
                nzShowSearch
                >
            </nz-transfer>

            @if (!fieldsReadOnly) {
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >
                    Guardar
                </button>
                <nz-divider nzType="vertical"></nz-divider>
                <button
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="cancelForm()"
                >
                    Cancelar
                </button>
            }
        </form>
    </ng-container>
</nz-drawer>
<!-- <div id="drawer-form">
	<app-drawer-form [(visible)]="showDrawerForm" [drawerMode]="drawerMode" [title]="title" [routeActually]="routeActually"
		[formStructure]="formStructure" [listValue]="listValue" [componentService]="screensService"
		(changeSelectedItem)="changeSelected($event)">
	</app-drawer-form>
</div>
<div id="drawer-assign-machines">
	<app-assign-machines [(visible)]="showDrawerAssignMachines" [title]="'Asignar máquinas'" [screen]="screenSelected">
	</app-assign-machines>
</div>
 -->
