import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ProductionOrderDto } from 'src/app/shared/dto/production-order.dto';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { ProductionTimeDto } from 'src/app/shared/dto/production-time.dto';
import { SetEndDateParams } from 'src/app/shared/dto/set-end-date-params.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { ArticleBatchesService } from './article-batches.service';
import { ArticlesService } from './articles.service';
import { ProductionOrderService } from './production-order.service';

@Injectable({
    providedIn: 'root',
})
export class ProductionOrderProcessesService extends BaseService {
    constructor(
        httpClient: HttpClient,
        private orderService: ProductionOrderService
    ) {
        super(httpClient, 'production-order-processes');
    }

    async setProductionProcessAsStarted(orderId: number, orderProcessId: number, userLoggedId: number): Promise<ProductionOrderProcessDto> {
        try {
            const request = this.httpClient.post<ProductionOrderProcessDto>(`${this.url}/setAsStarted`, {
                orderProcessId: orderProcessId,
                startedById: userLoggedId
            });

            const updatedProcess: ProductionOrderProcessDto = await lastValueFrom(request);

            const order: ProductionOrderDto = this.orderService.getOrderByIdFromLocalStorage(orderId);

            const processIndex: number = order.productionOrderProcesses!.findIndex((process) => process.id === orderProcessId);

            if (processIndex < 0) {
                throw new Error(`No se ha podido encontrar el processo ${orderProcessId} en la orden ${orderId}`);
            }

            order.productionOrderProcesses![processIndex] = updatedProcess;

            this.orderService.updateOrderLocalStorage(order, false);

            return updatedProcess;
        } catch (error: any) {
            throw error;
        }
    }

    async setProductionProcessAsFinished(orderId: number, orderProcessId: number, userLoggedId: number): Promise<ProductionOrderProcessDto> {
        try {
            const request = this.httpClient.post<any>(`${this.url}/setAsFinished`, {
                orderProcessId: orderProcessId,
                finishedById: userLoggedId
            });

            const updatedProcess: ProductionOrderProcessDto = await lastValueFrom(request);

            const order: ProductionOrderDto = this.orderService.getOrderByIdFromLocalStorage(orderId);

            const processIndex: number = order.productionOrderProcesses!.findIndex((process) => process.id === orderProcessId);

            if (processIndex < 0) {
                throw new Error(`No se ha podido encontrar el processo ${orderProcessId} en la orden ${orderId}`);
            }

            order.productionOrderProcesses![processIndex] = updatedProcess;

            this.orderService.updateOrderLocalStorage(order, false);

            return updatedProcess;
        } catch (error: any) {
            throw error;
        }
    }

    async setOrderProcessAsPaused(pause: ProductionPauseDto): Promise<ProductionOrderProcessDto> {
        try {
            const request = this.httpClient.post<ProductionOrderProcessDto>(`${this.url}/setAsPaused`, pause);

            const updatedProcess: ProductionOrderProcessDto = await lastValueFrom(request);

            return updatedProcess;
        } catch (error: any) {
            throw new Error(`No se ha podido establecer el proceso como pausado. Error: ${error.message}`);
        }
    }

    async setOrderProcessAsResumed(params: SetEndDateParams): Promise<ProductionOrderProcessDto> {
        try {
            const request = this.httpClient.patch<ProductionOrderProcessDto>(`${this.url}/resumePaused`, params);

            const updatedProcess: ProductionOrderProcessDto = await lastValueFrom(request);

            return updatedProcess;
        } catch (error: any) {
            throw new Error(`No se ha podido establecer el proceso como reanudado. Error: ${error.message}`);
        }
    }

    async getProductionTimeByOrderProcessId(orderProcessId: number): Promise<ProductionTimeDto> {
        try {
            const request = this.httpClient.get<ProductionTimeDto>(`${this.url}/getProductionTime/${orderProcessId}`);

            const response = await lastValueFrom(request);

            return response;
        } catch (error: any) {
            throw new Error(`No se ha podido obtener el tiempo de producción para el proceso. Error: ${error.message}`);
        }
    }

    async getTimerByProductionOrderProcessId(productionOrderProcessId: number): Promise<number> {
        try {
            const request = this.httpClient.get<number>(`${this.url}/getElapsedTime/${productionOrderProcessId}`);

            return await lastValueFrom(request);
        } catch (error: any) {
            throw new Error(`No se ha podido obtener el timer del proceso. Error: ${error.message}`);
        }
    }

    async updateQuantityProduced(orderProcessId: number, quantityProduced: number): Promise<ProductionOrderProcessDto> {
        try {
            const request = this.httpClient.patch<ProductionOrderProcessDto>(`${this.url}/updateQuantityProduced/${orderProcessId}`, {quantityProduced: quantityProduced});

            return await lastValueFrom(request);
        } catch (error: any) {
            throw new Error(`No se ha podido actualizar la cantidad producida. Error: ${error.message}`);
        }
    }

    async setArticleBatches(orderProcessId: number, articleBatches: ArticleBatchDto[]): Promise<ProductionOrderProcessDto> {
        try {
            if (orderProcessId <= 0 || !articleBatches || articleBatches.length === 0) {
                throw new Error('Id del proceso o ids de los lotes están vacíos o no son válidos');
            }

            const request = this.httpClient.patch<ProductionOrderProcessDto>(`${this.url}/setArticleBatches/${orderProcessId}`, { articleBatches: articleBatches });

            const updatedProcess: ProductionOrderProcessDto = await lastValueFrom(request);

            return updatedProcess;
        } catch (error: any) {
            throw new Error(`No se ha podido asignar el lote al proceso. Error: ${error.message}`);
        }
    }

    async updateQualityControlCounter(counter: number, orderProcessId: number): Promise<ProductionOrderProcessDto> {
        try {
            if (orderProcessId == null || orderProcessId <= 0) {
                throw new Error('Order process id can not be null or negative');
            }
            
            return await lastValueFrom(this.httpClient.patch<ProductionOrderProcessDto>(`${this.url}/updateQualityControlCounter/${orderProcessId}`, {qualityControlCounter: counter}));
        } catch (e: any) {
            throw new Error(`No se han podido obtener las prioridades. Error: ${e.message}`);
        }
    }

    async preStartProcess(orderProcessId: number): Promise<void> {
        try {
            const request = this.httpClient.post<ProductionOrderProcessDto>(`${this.url}/preStartProcess`, {
                orderProcessId: orderProcessId
            });

            await lastValueFrom(request);
        } catch (error: any) {
            throw error;
        }
    }

    increaseStockQuantities(productionOrderProcess: ProductionOrderProcessDto, articles: ArticleDto[], articlesBatches: ArticleBatchDto[]) {
        productionOrderProcess.rawMaterials?.forEach(rawMaterial => {
            if (rawMaterial.article) {
                articles.find(article => article.id == rawMaterial.article!.id)!.quantity += rawMaterial.quantity;
            } else if (rawMaterial.articleBatch) {
                articlesBatches.find(articleBatch => articleBatch.id == rawMaterial.articleBatch!.id)!.quantity += rawMaterial.quantity;
            }
        });

        return {productionOrderProcess, articles, articlesBatches};
    }

    decreaseStockQuantities(productionOrderProcess: ProductionOrderProcessDto, articles: ArticleDto[], articlesBatches: ArticleBatchDto[]) {
        productionOrderProcess.rawMaterials?.forEach(rawMaterial => {
            if (rawMaterial.article) {
                articles.find(article => article.id == rawMaterial.article!.id)!.quantity -= rawMaterial.quantity;
            } else if (rawMaterial.articleBatch) {
                articlesBatches.find(articleBatch => articleBatch.id == rawMaterial.articleBatch!.id)!.quantity -= rawMaterial.quantity;
            }
        });

        return {productionOrderProcess, articles, articlesBatches};
    }
}
