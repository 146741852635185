<div *nzModalTitle class="px-0 py-1 w-full grid grid-cols-2 gap-4 place-content-evenly">
    <div style="display: flex; align-items: center;">
		<p class="modal-title">Nueva Merma de {{ articleName }} - Orden {{ processId }} | {{ processDescription }}</p>
    </div>
</div>

<div class="mx-4">
	<form nz-form [nzLayout]="'vertical'" [formGroup]="validateForm" (ngSubmit)="submitForm()">
		<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" class="p-4">
			<nz-form-item nz-col nzXs="24" nzSm="12" nzMd="8" class="px-4">
				<nz-form-label nzRequired>
					Cantidad de producto mermado
				</nz-form-label>
				<nz-form-control nzErrorTip="Indique la cantidad de producto mermado">
					<input formControlName="quantity" nz-input type="number" placeholder="Indique la cantidad de producto mermado"/>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item nz-col nzXs="24" nzSm="12" nzMd="8" class="px-4">
				<nz-form-label nzRequired>
					Unidad de medida
				</nz-form-label>
				<nz-form-control nzErrorTip="Indique la unidad de medida de la merma">
					<nz-select formControlName="selectedMeasurementUnit" 
						nzPlaceHolder="Indique la unidad de medida de la merma"
						(ngModelChange)="setMeasurement($event)">
						@for (measurement of measurementUnits; track measurement) {
							<nz-option nzValue="{{ measurement.id }}" nzLabel="{{ measurement.abbreviation }}">
							</nz-option>
						}
					</nz-select>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item nz-col nzXs="24" nzSm="24" nzMd="24" class="px-4">
				<nz-form-label nzRequired>
					Observaciones
				</nz-form-label>
				<nz-form-control nzErrorTip="Indique información acerca de la merma">
					<textarea formControlName="observations" nz-input rows="4" placeholder="Indique información acerca de la merma" class="searchSelectors"></textarea>
				</nz-form-control>
			</nz-form-item>
		</div>
	</form>
</div>

<div *nzModalFooter>
    <div class="flex justify-end">
        <button
        type="submit"
        (click)="submitForm()"
		[disabled]="!validateForm.valid"
        class="button primary-button mr-2"
        >
            Crear
        </button>

        <button
        (click)="handleCancel()"
        class="button secondary-button"
        >
            Cancelar
        </button>
    </div>
</div>