<div *nzModalTitle class="px-0 py-1 w-full grid grid-cols-2 gap-4 place-content-evenly">
    <div style="display: flex; align-items: center;">
      <p class="modal-title">{{data.data.nzTitle}}</p>
    </div>
  
    <div class="flex justify-end">
        <button (click)="handleAddRawMaterial()"
        class="button primary-button"
        >
            Añadir materia prima
        </button>
    </div>
</div>

<div #modalContent class="w-full h-full p-0 m-0">
    @if (plainRawMaterials && plainRawMaterials.length > 0) {
        <nz-table
            #basicTable
            [nzData]="plainRawMaterials"
            class="m-0 p-0"
            nzSize="small"
            [nzLoading]="isLoading"
            nzTableLayout="auto"
            [nzBordered]="true"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
            [nzScroll]="{ y: (tableHeight || 0) + 'px' }"
            >
            <thead>
            <tr>
                <th id="article" nzWidth="20%">Artículo</th>
                <th id="batch" nzWidth="20%">Lote</th>
                <th id="packaging" nzWidth="20%">Formato</th>
                <th id="actualQuantity" nzWidth="10%">Cantidad actual</th>
                <th id="quantity"nzWidth="10%">Cantidad/Ud. a producir</th>
                <th id="totalQuantity" nzWidth="10%">Cantidad total a consumir</th>
                <th id="action" nzWidth="10%">Acciones</th>
            </tr>
            </thead>
            <tbody>
            @for (rawMaterial of basicTable.data; track rawMaterial; let idx = $index) {
                <tr>
                    <td>
                        <nz-select nzShowSearch class="w-full" [(ngModel)]="rawMaterial.article" (ngModelChange)="onArticleChanged($event, idx)"
                        [compareWith]="compareById" [nzFilterOption]="filterArticlesSelector">
                            @for(article of articles; track article){
                                <nz-option [nzValue]="article" [nzLabel]="article.name"></nz-option>
                            }
                        </nz-select>
                    </td>
                    <td>
                        <nz-select nzShowSearch class="w-full" [(ngModel)]="rawMaterial.articleBatch" [compareWith]="compareByBatchNumber"
                        [disabled]="!plainRawMaterials[idx].article?.useBatches" (ngModelChange)="onBatchChanged(rawMaterial.articleBatch, idx)"
                        (nzOnSearch)="onArticleBatchSearch($event, idx)"
                        [nzFilterOption]="filterArticleBatchesSelector">
                            @for(batch of batchesOfArticlesInRawMaterials.get(idx); track batch.batchNumber){
                                <nz-option [nzValue]="batch" [nzLabel]="batch.batchNumber || ''"></nz-option>
                            }
                        </nz-select>
                    </td>
                    <!-- TODO: Remove if finally we use individual selecetor to packaging -->
                    <!-- <td>
                        <nz-select nzMode="multiple"
                        nzPlaceHolder="Selecciona formatos" [(ngModel)]="rawMaterial.packaging"
                        name="packagingSelector" class="w-full"
                        [nzDisabled]="rawMaterial.packaging === undefined" [compareWith]="compareByPackagingElementId">
                        @for(packaging of packagingsOfBatchesSelected.get(idx); track packaging.packagingElementId){
                            <nz-option [nzValue]="packaging" [nzLabel]="packaging.packagingElementId + ' ' + packaging.name"></nz-option>
                        }
                        </nz-select>
                    </td> -->
                    <td>
                        <nz-select nzShowSearch class="w-full" [(ngModel)]="rawMaterial.packaging" [compareWith]="compareByPackagingElementId"
                        [disabled]="rawMaterial.packaging === undefined" [nzFilterOption]="filterPackagingOnArticleBatchSelector">
                            @for(packaging of packagingsOfBatchSelected.get(idx); track packaging.packagingElementId){
                                    <nz-option [nzValue]="packaging" [nzLabel]="packaging.externalBatchNumber + ' ' + packaging.name"></nz-option>
                            }
                        </nz-select>
                    </td>
                    <td>
                        {{rawMaterial.articleBatch?.quantity || rawMaterial.article?.quantity}} {{rawMaterial.measurementUnit}}
                    </td>
                    <td>
                        <nz-input-group [nzSuffix]="rawMaterial.measurementUnit">
                            <input nz-input [(ngModel)]="rawMaterial.quantity" type="number" />
                        </nz-input-group>
                    </td>
                    <td>
                        {{quantityToProduce * rawMaterial.quantity}} {{rawMaterial.measurementUnit}}
                    </td>
                    <td>
                        <div class="flex justify-center">
                            <button
                            (click)="deleteRawMaterial(idx)"
                            class="button neutral-outlined-button"
                            >
                                Eliminar
                            </button>
                        </div>
                    </td>
                </tr>
            }
            </tbody>
        </nz-table>
    }
    @else {
        <div class="w-full h-full content-center">
            <nz-empty></nz-empty>
        </div>        
    }
</div>

<div *nzModalFooter>
    <div class="flex justify-end">
        <button
        type="submit"
        (click)="handleOk()"
        class="button primary-button mr-2"
        >
            Aceptar
        </button>

        <button
        (click)="handleCancel()"
        class="button secondary-button"
        >
            Cancelar
        </button>
    </div>
</div>
