import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NZ_MODAL_DATA, NzModalService, NzModalTitleDirective, NzModalFooterDirective } from 'ng-zorro-antd/modal';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { NzTableComponent, NzTheadComponent, NzTrDirective, NzTableCellDirective, NzThMeasureDirective, NzTbodyComponent } from 'ng-zorro-antd/table';
import { DatePipe } from '@angular/common';
import { LossDto } from 'src/app/shared/dto/loss.dto';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@Component({
    selector: 'app-modal-watch-losses',
    templateUrl: './modal-watch-losses.component.html',
    styleUrls: ['./modal-watch-losses.component.scss'],
    standalone: true,
    imports: [
    NzModalTitleDirective,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzTbodyComponent,
    NzModalFooterDirective,
    NzEmptyModule,
    DatePipe
],
})
export class ModalWatchLossesComponent {
    @ViewChild('modalContent') private readonly _modalContent!: ElementRef;

    isLoading: boolean = true;
    losses: LossDto[] = [];
    article!: ArticleDto;
    articleBatch!: ArticleBatchDto;
    processId: number = 0;
    processDescription: string = '';
    articleName: string = '';
    tableHeight!: number;

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private _modalService: NzModalService,
    ) {
        this.losses = (data?.data?.losses as LossDto[]) ?? [];
        this.article = (data?.data?.article as ArticleDto) ?? undefined;
        this.articleBatch = (data?.data?.articleBatch as ArticleBatchDto) ?? undefined;
        this.processId = data?.data?.processId as number;
        this.processDescription = data?.data?.processDescription as string;

        this.articleName = this.article.name ?? this.articleBatch.article?.name;

        this.isLoading = false;
    }

    async ngAfterViewInit(): Promise<void> {
        setTimeout(() => {
            this.tableHeight =
                (this._modalContent.nativeElement as HTMLImageElement)
                    .clientHeight-29; // 29 is the size of the title
        });
    }

    handleCancel(): void {
        this._modalService.closeAll();
    }
}
