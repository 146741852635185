<div class="p-10 h-full">
    <nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Órdenes de producción">
        <nz-page-header-extra class="flex">
            <!------------------------------------------------>
            <!------------------- FILTER INPUT --------------->
            <div class="relative mr-4">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                        </path>
                    </svg>
                </div>

                <nz-input-group [nzPrefix]="searchIcon" [nzSuffix]="inputClearTpl"
                    class="rounded-lg border border-gray-300" style="width: 250px">
                    <input
                        class="block px-4 text-[15px] font-medium py-1 pl-10 w-[275px] text-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Nº Orden / Cliente / Máq" nz-input [(ngModel)]="filterText"
                        (ngModelChange)="saveLastTextFilter()" (keyup.enter)="filterOrders()" />
                </nz-input-group>
                <ng-template #inputClearTpl>
                    @if (filterText) {
                    <span class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" (click)="clearFilter()"
                        nz-icon></span>
                    }
                </ng-template>
                <ng-template #searchIcon>
                    <span nz-icon nzType="search"></span>
                </ng-template>
            </div>
            <!------------------------------------------------>
            <!--------- MOSTRAR PENDIENTES/FINALIZADAS-------->
            @if (isAdmin) {
            <div class="flex mr-3">
                @if (showButton('urgentBtn')) {
                    <button (click)="setSelectedOrderAsUrgent()"
                        class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-semibold rounded-md text-[13px] px-5 py-1.5 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 w-full">
                        Marcar como urgente
                    </button>&nbsp;
                }
                @if (showFinished) {
                    <button (click)="setShowFinished(false)"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">
                        Mostrar Pendientes
                    </button>
                } @else {
                    <button (click)="setShowFinished(true)"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">
                        Mostrar Finalizadas
                    </button>
                }
                @if (showButton('newBtn')) {
                    <button
                        class="ml-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full"
                        (click)="openCreateOrderDrawer()">
                        Nuevo
                    </button>
                }
            </div>
            }
        </nz-page-header-extra>
        <nz-page-header-content> </nz-page-header-content>
    </nz-page-header>

    <div #tableContainer class="mt-[15px] h-[calc(100%_-_55px)] max-h-[calc(100%_-_55px)]">
        <nz-table #nestedTable nzTableLayout="auto" nzSize="small" nzTableLayout="auto"
            [nzData]="productionOrdersFiltered" [nzLoading]="loading" [nzSimple]="true"
            [nzLoadingIndicator]="indicatorTemplate" [nzTotal]="countOrders" [nzBordered]="true"
            [nzFrontPagination]="false" [nzScroll]="{ y: (tableHeight || 0) + 'px' }" nzShowSizeChanger nzBordered
            (nzQueryParams)="onQueryParamsChange($event)">
            <thead>
                <tr>
                    @if (isAdmin) {
                    <th nzWidth="2%"></th>
                    }
                    <th>Núm</th>
                    <th (click)="sortByPriorityOrder()" style="cursor: pointer;">
                        <div class="flex items-center">
                            Orden Prioridad
                            <img [src]="'/assets/icons/sort-'+prioritySortState+'-icon.svg'" class="h-3 w-3"
                                alt="Orden Prioridad">
                        </div>
                    </th>
                    <th (click)="sortByPriority()" style="cursor: pointer;">
                        <div class="flex items-center">
                            Prioridad
                            <img [src]="'/assets/icons/sort-'+prioritySortState+'-icon.svg'" class="h-3 w-3"
                                alt="Prioridad">
                        </div>
                    </th>
                    <th>Descripción</th>
                    <th>Fecha inicio</th>
                    <th>Cliente</th>
                    <th>Ruta</th>
                    <th>Estado</th>
                    @if (isAdmin) {
                    <th>Progreso</th>
                    }
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                @for (data of nestedTable.data; track data.id) {
                <tr class="hover:cursor-pointer text-[13px] min-h-[58px] h-[58px] {{
                            orderSelected !== null &&
                            orderSelected !== undefined &&
                            orderSelected.id === data.id
                                ? 'bg-gray-200 hover:bg-gray-200'
                                : ''
                        }}" (click)="
                            isAdmin && !showFinished
                                ? setOrderSelected($event, data)
                                : setOrderSelected($event, null)
                        ">
                    @if (isAdmin) {
                    <td [(nzExpand)]="data.expand" style="width: 20px"></td>
                    }
                    @if (isAdmin) {
                    <td class="text-sky-500 font-medium text-right align-middle hover:cursor-pointer"
                        (click)="navigateToOrden(data.id)">
                        {{ data.id }}
                    </td>
                    }
                    <td>
                        <div class="flex items-center justify-center">
                            <span>
                                {{ data.priorityOrder }}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div class="flex items-center justify-center">
                            <span [class]="data.priority?.cssClassName">
                                {{ data.priority?.name }}
                            </span>
                        </div>
                    </td>
                    <td class="text-center">
                        {{ data.description }}
                    </td>
                    <td class="text-center">
                        {{ data.startDate | date: "dd/MM/YYYY" }}
                    </td>

                    <td class="text-sky-500 font-medium" style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            ">
                        <a href="javascript:void(0)" (click)="customerFilter(data.customers?.name!)">
                            {{ data.customers?.name }}
                        </a>
                    </td>
                    <td>
                        {{
                        data.productionRoute
                        ? data.productionRoute.name
                        : "-"
                        }}
                    </td>

                    <td class="text-center align-middle">
                        <span [ngClass]="data.status?.cssClassName">
                            {{ data.status?.name }}
                        </span>
                    </td>

                    <!-- % REALIZADO DE ORDEN -->
                    @if (isAdmin) {
                    <td style="width: 140px !important;text-overflow: ellipsis;">
                        <nz-progress [nzPercent]="calculateStepper(data)" nzSize="small"></nz-progress>
                    </td>
                    }
                    <td style="width: 1px;">
                        <div class="buttonsDnDTableContainer">
                            <img src="assets/icons/eye-icon.svg" class="h-6 w-6" alt="Ver" (click)="viewOrder(data.id)"
                                title="Ver">
                            <nz-divider nzType="vertical"></nz-divider>
                            <img src="assets/icons/edit-icon.svg" class="h-6 w-6" alt="Editar"
                                (click)="editOrder(data.id)" title="Editar">
                            <nz-divider nzType="vertical"></nz-divider>
                            <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" alt="Eliminar"
                                (click)="deleteOrder(data.id)" title="Eliminar">
                        </div>
                    </td>
                </tr>

                <!------------------------------------------------------------------->
                <!--------------------------- EXPANDIDA ----------------------------->
                <!------------------------------------------------------------------->
                <tr [nzExpand]="data.expand?data.expand:false">
                    <nz-table #innerTable style="margin: -9px 0px !important"
                        [nzData]="data.productionOrderProcesses?data.productionOrderProcesses:[]"
                        [nzFrontPagination]="false" [nzShowPagination]="false" [nzBordered]="true" nzTableLayout="auto"
                        nzSize="small">
                        <thead>
                            <tr>
                                <th nzWidth="100px">Orden Fab.</th>
                                <th>Proceso</th>
                                <th nzWidth="120px">Estado</th>
                                <th>Máquina</th>
                                <th>Inicio</th>
                                <th>Fin</th>
                                <th>Duración</th>
                                <th>Realizado por</th>
                            </tr>
                        </thead>

            <tbody>
                @for ( process of innerTable.data; track process.id; let i = $index ) {
                <tr>
                    <td class="text-right align-middle">
                        <div class="flex items-center justify-end">
                            @if (
                            process.id !==
                            undefined &&
                            process.status?.name ===
                            ProductionOrderStates.PENDING
                            ) {
                            <div nz-button>
                                <svg class="w-5 h-5 text-yellow-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z">
                                    </path>
                                </svg>
                            </div>
                            }
                            <p>{{ i + 1 }}</p>
                        </div>
                    </td>

                    <td class="capitalize">
                        {{ process.description }}
                    </td>

                    <!---------------------------- ESTADOS -------------------------->
                    <!--------------------------------------------------------------->
                    <td class="text-center align-middle">
                        <span [ngClass]="process.status?.cssClassName">
                            {{ process.status?.name ?? '' }}
                        </span>
                    </td>

                    <!---------------------------- MAQUINAS -------------------------->
                    <!---------------------------------------------------------------->
                    @if (process.machine) {
                    <td class="capitalize">
                        {{ process.machine.name }}
                    </td>
                    }
                    @if (!process.machine) {
                    <td class="capitalize">
                        CUALQUIERA DE
                        {{ process.description }}
                    </td>
                    }

                    <!---------------------------- TIEMPOS --------------------------->
                    <!---------------------------------------------------------------->
                    <td class="capitalize">
                        @if (!process.productionTime) {
                        -
                        } @else {
                        {{
                        process.productionTime
                        .startDate
                        | date
                        : (datesAreOnSameDay(
                        process
                        .productionTime
                        .startDate
                        )
                        ? "HH:mm:ss"
                        : "dd/MM/YYYY HH:mm:ss a (O)")
                        }}
                        }
                    </td>
                    <td class="capitalize">
                        @if (!process.productionTime) {
                        -
                        } @else {
                        {{
                        process.productionTime
                        .startDate
                        | date
                        : (datesAreOnSameDay(
                        process
                        .productionTime
                        .endDate
                        )
                        ? "HH:mm:ss"
                        : "dd/MM/YYYY HH:mm:ss a (O)")
                        }}
                        }
                    </td>
                    <td class="capitalize">
                        @if (
                        !process.productionTime ||
                        !process.productionTime.endDate
                        ) {
                        -
                        } @else {
                        {{
                        getTotalDuration(
                        process.productionTime
                        .startDate,
                        process.productionTime
                        .endDate,
                        process.productionPauses!
                        )
                        }}
                        }
                    </td>
                    <td class="capitalize">
                        @if (
                        process.productionPauses!
                        .length <= 0 ) { - } @else { {{ process.productionPauses![0].pausedBy?.name }} } </td>
                </tr>
                }
            </tbody>
        </nz-table>
        </tr>
        }
        </tbody>
        </nz-table>
    </div>
</div>

<ng-template #indicatorTemplate>
    <div role="status">
        <svg class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mt-24 mr-4"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
        </svg>
    </div>
</ng-template>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="handleDrawerClose()" (nzOnClose)="closeDrawer()" [nzWidth]="'60%'"
    [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Descripción:</label>
            <input nz-input [value]="selectedEditOrder.description ? selectedEditOrder.description : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly || orderStarted" id="description" />

            <label>Cliente:</label>
            <nz-select nzShowSearch nzPlaceHolder="Selecciona un cliente" [(ngModel)]="customerSelected"
                name="customerSelector" class="searchSelectors" [nzDisabled]="fieldsReadOnly || orderStarted" [compareWith]="compareById">
                @for (c of customers; track c.id) {
                    <nz-option [nzLabel]="c.name" [nzValue]="c"></nz-option>
                }
            </nz-select>
            <br /><br />

            <label>Prioridad:</label>
            <nz-select nzShowSearch nzPlaceHolder="Selecciona una prioridad" [(ngModel)]="prioritySelected"
                name="prioritySelector" class="searchSelectors" [nzDisabled]="fieldsReadOnly" [compareWith]="compareById">
                @for (p of priorities; track p.id) {
                    <nz-option [nzLabel]="p.name" [nzValue]="p"></nz-option>
                }
            </nz-select>
            <br /><br />

            <label>Orden Prioridad:</label>
            <input nz-input [value]="selectedEditOrder.priorityOrder ? selectedEditOrder.priorityOrder : ''" type="number"
                class="searchSelectors" [readOnly]="fieldsReadOnly || orderStarted" id="priorityOrder" />
            <br /><br />

            <label>Referencia:</label>
            <input nz-input [value]="selectedEditOrder.reference ? selectedEditOrder.reference : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly || orderStarted" id="reference" />

            <label>Ruta:</label>
            <nz-select nzShowSearch nzPlaceHolder="Selecciona una ruta" [(ngModel)]="routeSelected"
                (ngModelChange)="onRouteChange($event)" name="routeSelector" class="searchSelectors"
                [nzDisabled]="fieldsReadOnly || orderStarted" [nzAllowClear]="true" [compareWith]="compareById">
                @for (r of routes; track r.id) {
                <nz-option [nzLabel]="r.name" [nzValue]="r"></nz-option>
                }
            </nz-select>
            <br /><br />

            <label>Operarios (Opcional)</label>
            <nz-select [nzMaxTagCount]="5" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                nzPlaceHolder="Selecciona los operarios" [(ngModel)]="selectedUsers"
                name="operatorSelector" class="searchSelectors"
                [nzDisabled]="fieldsReadOnly || orderStarted" [compareWith]="compareUsers">
                @for (user of users; track user.id) {
                    <nz-option [nzLabel]="user.name" [nzValue]="user"></nz-option>
                }
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>y {{ selectedList.length }} mas seleccionados</ng-template>
            <br /><br />

            <label>Procesos de producción</label>
            <nz-select [nzMaxTagCount]="5" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                nzPlaceHolder="Selecciona" [(ngModel)]="selectedProductionProcesses"
                (ngModelChange)="onProductionProcessessChange($event)" name="processSelector" class="searchSelectors"
                [nzDisabled]="fieldsReadOnly || orderStarted" [compareWith]="compareById">
                @for (process of productionProcessess; track process.id) {
                <nz-option [nzLabel]="process.name" [nzValue]="process"></nz-option>
                }
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>y {{ selectedList.length }} mas seleccionados</ng-template>
            <br>

            <nz-table [nzData]="productionProcessTableRows"
                [nzFrontPagination]="false" [nzShowPagination]="false">
                <thead>
                    <tr>
                        <th colspan="9">
                            @if (!fieldsReadOnly || !orderStarted) {
                            Orden de los procesos (Arrastra para cambiar)
                            }
                            @else {
                            Orden de los procesos
                            }
                        </th>
                    </tr>
                    <tr>
                        <th>º</th>
                        <th>Proceso</th>
                        <th>Descripción</th>
                        <th>Cantidad a producir</th>
                        <th>Máquina</th>
                        <th>Escandallo</th>
                        <th>Artículo</th>
                        <th>Lotes</th>
                        <th [hidden]="fieldsReadOnly || orderStarted">Acciones</th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    @for (productionProcessRow of productionProcessTableRows; track idx; let idx = $index) {
                    <tr cdkDrag class="align-top">
                        <td class="searchSelectorsText">
                            {{ idx + 1 + 'º' }}
                        </td>
                        <td class="searchSelectorsText">
                            {{ productionProcessRow.productionProcess.name }}
                        </td>
                        <td>
                            <input type="text" pattern="\d*" class="w-6" [readonly]="fieldsReadOnly || orderStarted"
                                class="searchSelectors" [(ngModel)]="productionProcessRow.description"
                                [name]="'processDescription' + idx" />
                        </td>
                        <td>
                            <input type="number" pattern="\d*" class="w-6" [readonly]="fieldsReadOnly || orderStarted"
                                class="searchSelectors" [(ngModel)]="productionProcessRow.quantityToProduce"
                                [name]="'process' + idx" />
                        </td>
                        <td>
                            <nz-select nzShowSearch nzPlaceHolder="Selecciona una máquina"
                                [(ngModel)]="productionProcessRow.machine" [name]="'machineSelector' + idx"
                                class="searchSelectors" [nzDisabled]="fieldsReadOnly || orderStarted"
                                [compareWith]="compareById">
                                @for (r of machines; track subidx; let subidx = $index) {
                                <nz-option [nzLabel]="r.name" [nzValue]="r"></nz-option>
                                }
                            </nz-select>
                        </td>
                        <td>
                            <div class="flex">
                                <nz-select nzShowSearch nzPlaceHolder="Selecciona una escandallo"
                                    [(ngModel)]="productionProcessRow.assess" [name]="'assessSelector' + idx"
                                    class="searchSelectors" [nzDisabled]="fieldsReadOnly || orderStarted"
                                    (ngModelChange)="onAssessChange($event, idx)" [compareWith]="compareById">
                                    @for (assess of assessments; track subidx; let subidx = $index) {
                                    <nz-option [nzLabel]="assess.description" [nzValue]="assess"></nz-option>
                                    }
                                </nz-select>
                                <button type="button" (click)="onShowRawMaterials(productionProcessRow)" class="w-12 h-12 flex items-center justify-center p-0">
                                    <img src="assets/icons/plus-icon.svg" alt="Añadir materias primas" class="h-6 w-6"
                                        title="Añadir materias primas">
                                </button>
                            </div>
                        </td>
                        <td>
                            <nz-select nzShowSearch nzPlaceHolder="Selecciona una artículo"
                                [(ngModel)]="productionProcessRow.article" [name]="'articleSelector' + idx"
                                class="searchSelectors"
                                [nzDisabled]="productionProcessRow.assess !== null || orderStarted"
                                [compareWith]="compareById">
                                @for (article of articles; track subidx; let subidx = $index) {
                                <nz-option [nzLabel]="article.name" [nzValue]="article"></nz-option>
                                }
                            </nz-select>
                        </td>
                        <td>
                            <button
                                type="button" (click)="onAddFinalBatches(productionProcessRow, idx)" class="w-12 h-12 flex items-center justify-center p-0">
                                <img src="assets/icons/plus-icon.svg" alt="Añadir lotes" class="h-6 w-6"
                                    title="Añadir lotes">
                            </button>
                        </td>
                        <td [hidden]="fieldsReadOnly || orderStarted">
                            <div class="w-12 h-12 flex items-center justify-center p-0" >
                                <img src="assets/icons/copy-icon.svg" alt="Duplicar" class="h-6 w-6"
                                    (click)="cloneProductionOrderProcess(idx)" title="Duplicar">
                                <nz-divider nzType="vertical"></nz-divider>
                                <img src="assets/icons/xmark-icon.svg" alt="Eliminar" class="h-6 w-6"
                                    (click)="removeProductionOrderProcess(idx)" title="Eliminar">
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </nz-table>

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            }
        </form>
    </ng-container>
</nz-drawer>
