<div *nzModalTitle class="px-0 py-1 w-full grid grid-cols-2 gap-4 place-content-evenly">
  <div style="display: flex; align-items: center;">
    <p class="modal-title">{{data.data.nzTitle}}</p>
  </div>
</div>
<div #modalContent class="w-full h-full p-0 m-0">
  @if (pauses && pauses.length > 0) {
    <nz-table
      #basicTable
      [nzData]="pauses"
      class="m-0 p-0"
      nzSize="small"
      [nzLoading]="isLoading"
      nzTableLayout="auto"
      [nzBordered]="true"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ y: (tableHeight || 0) + 'px' }"
      >
      <thead>
        <tr>
          <th nzWidth="250px">Motivo</th>
          <th>Inicio</th>
          <th>Operario Inicio Pausa</th>
          <th>Fin</th>
          <th>Operario Reanudación</th>
          <th nzWidth="15%">Duración</th>
        </tr>
      </thead>
      <tbody>
        @for (data of basicTable.data; track data) {
          <tr>
            <td>{{ data.observations }}</td>
            <td class="text-right">
              {{
              data.startDate
              | date
              : (datesAreOnSameDay(data.startDate!)
              ? "HH:mm:ss"
              : "dd/MM/YYYY HH:mm:ss a (O)")
              }}
            </td>
            <td>{{ data.pausedBy ? data.pausedBy.name : "-" }}</td>
            <td class="text-right">
              {{
              data.endDate === null
              ? "-"
              : (data.endDate
              | date
              : (datesAreOnSameDay(data.endDate!)
              ? "HH:mm:ss"
              : "dd/MM/YYYY HH:mm:ss a (O)"))
              }}
            </td>
            <td>{{ data.pausedBy ? data.pausedBy.name : "-" }}</td>
            <td class="text-right">
              {{
              data.endDate === null
              ? "-"
              : milisecondsToFormatTime(
              data.startDate,
              data.endDate
              )
              }}
            </td>
          </tr>
        }
      </tbody>
    </nz-table>
  }
  @else {
      <div class="w-full h-full content-center">
          <nz-empty></nz-empty>
      </div>        
  }
</div>

<div *nzModalFooter>
  <div class="flex justify-end">
      <button
      (click)="handleCancel()"
      class="button secondary-button"
      >
        Cerrar
      </button>
  </div>
</div>