<div *nzModalTitle class="px-0 py-1 w-full grid grid-cols-2 gap-4 place-content-evenly">
    <div style="display: flex; align-items: center;">
      <p class="modal-title">Ver Mermas de {{ articleName }} - Orden {{ processId }} | {{ processDescription }}</p>
    </div>
</div>

<div class="w-full h-full p-0 m-0 mb-0.5">
	@if (losses && losses.length > 0) {
		<nz-table 
			#basicTable 
			[nzData]="losses" 
			class="m-0 p-0"
            nzSize="small"
            [nzLoading]="isLoading"
            nzTableLayout="auto"
            [nzBordered]="true"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
            [nzScroll]="{ y: (tableHeight || 0) + 'px' }"
            >
			<thead>
				<tr>
					<th>Observaciones</th>
					<th nzWidth="120px">Cantidad</th>
					<th>Fecha</th>
					<th>Operario</th>
				</tr>
			</thead>
			<tbody>
				@for (data of basicTable.data; track data) {
				<tr>
					<td>{{ data.observations }}</td>
					<td class="text-right">
						{{ data.quantity }}{{ data.measurementUnit?.abbreviation }}
					</td>
					<td>{{ (data.date | date: "dd/MM/YYYY HH:mm:ss") }}</td>
					<td>{{ data.createdBy?.name }}</td>
				</tr>
				}
			</tbody>
		</nz-table>
	}
	@else {
		<div class="w-full h-full content-center">
			<nz-empty></nz-empty>
		</div>        
	}
</div>

<div *nzModalFooter>
    <div class="flex justify-end">
        <button
        (click)="handleCancel()"
        class="button secondary-button"
        >
            Cerrar
        </button>
    </div>
</div>