import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SensorDto } from 'src/app/shared/dto/sensor.dto';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root'
})
export class SensorsService {
    private machineSensorsSubject = new BehaviorSubject<SensorDto>(new SensorDto());
    public machineSensors$ = this.machineSensorsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    async setupWebSocket(): Promise<void> {
        this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SENSORS_ROOM));
        this.socketService.getNotifications(Messages.SENSOR_DATA_CHANGED).subscribe(async (data) => {
            this.validateSensorData(data);
            
            let sensorData: SensorDto = data as SensorDto;
            console.log(sensorData);
            this.machineSensorsSubject.next(sensorData);
        });
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SENSORS_ROOM));
    }

    private validateSensorData(sensorData: any) {
        if (!sensorData) {
            throw new Error('Los datos del sensor con invalidos.');
        }

        if (!sensorData.id || typeof sensorData.id !== 'number' || sensorData.id < 0) {
            throw new Error('El id del sensor ha de ser valido.');
        }

        if (!sensorData.value || typeof sensorData.value !== 'number') {
            throw new Error('El valor del sensor ha de ser valido.');
        }

        if (!sensorData.orderId || typeof sensorData.orderId !== 'number' || sensorData.orderId < 0) {
            throw new Error('El id de la orden en el sensor ha de ser valido.');
        }

        if (!sensorData.orderProcessId || typeof sensorData.orderProcessId !== 'number' || sensorData.orderProcessId < 0) {
            throw new Error('El id del proceso en el sensor ha de ser valido.');
        }

        if (!sensorData.status || typeof sensorData.status !== 'string') {
            throw new Error('El estado de la máquina en el sensor ha de ser valido');
        }
    }
}
