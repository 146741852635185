import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
    }

    navigate(err: string) {
        this.router.navigate(['/error'], { state: { error: err } });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const excludedUrls = ['users/updatePassword/'];
        if (excludedUrls.some((url) => request.url.includes(url))) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catchError((err) => {
                if ([401, 403].indexOf(err.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    this.logout();
                }

                if ([500].indexOf(err.status) !== -1) {
                    const errMessage = err.error?.message || err.statusText || 'Error desconocido';
                    this.navigate(errMessage);
                }

                return throwError(err);
            }),
        );
    }
}
