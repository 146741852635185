import { Component, ElementRef, EventEmitter, inject, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService, NzModalTitleDirective } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective } from 'ng-zorro-antd/table';
import { NzInputDirective, NzInputGroupComponent, NzInputGroupWhitSuffixOrPrefixDirective } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { Subject, takeUntil } from 'rxjs';
import { ArticleBatchesService } from 'src/app/private/services/article-batches.service';
import { ArticlesService } from 'src/app/private/services/articles.service';
import { PackagingsService } from 'src/app/private/services/packagings.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { RawMaterialDto } from 'src/app/shared/dto/rawMaterial.dto';
import { PackagingDto } from '../../../../shared/dto/packaging.dto';


interface PlainRawMaterial {
    name: string;
    article?: ArticleDto;
    articleBatch?: ArticleBatchDto;
    packaging?: PackagingDto[];
    measurementUnit?: string;
    quantity: number;
}

@Component({
    selector: 'app-modal-raw-material',
    templateUrl: './modal-raw-material.component.html',
    styleUrls: ['./modal-raw-material.component.scss'],
    standalone: true,
    imports: [
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzModalTitleDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        ReactiveFormsModule,
        FormsModule,
        NzModalFooterDirective,
        NzMessageModule,
        NzSelectModule,
        NzInputDirective, 
        NzInputGroupComponent, 
        NzInputGroupWhitSuffixOrPrefixDirective,
        NzEmptyModule
    ],
})
export class ModalRawMaterialComponent implements OnInit, OnDestroy {
    @Output() emitService = new EventEmitter();
    @ViewChild('modalContent') private readonly _modalContent!: ElementRef;

    isLoading: boolean = true;
    rawMaterials: RawMaterialDto[] = [];
    plainRawMaterials: PlainRawMaterial[] = [];
    articles: ArticleDto[] = [];
    articleBatches: ArticleBatchDto[] = [];
    articlesAndBatches: any = [];
    batchesOfArticlesInRawMaterials = new Map<number, ArticleBatchDto[]>();
    packagingsOfBatchSelected = new Map<number, PackagingDto[]>();
    quantityToProduce: number = 0;
    auxModel: number = 0;
    tableHeight!: number;

    private articleBatchNumberSearchValue: string = '';

    private readonly unsubscribe$ = new Subject();

    private readonly articleService = inject(ArticlesService);
    private readonly articleBatchService = inject(ArticleBatchesService);
    private readonly packagingsService = inject(PackagingsService);

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private readonly modalService: NzModalService,
        private readonly toast: NzMessageService,
    ) {
        this.rawMaterials = data.data.rawMaterials;
        this.quantityToProduce = data.data.quantityToProduce;

        if (this.rawMaterials) {
            this.rawMaterials.forEach((rawMaterial: RawMaterialDto, index) => {

                this.plainRawMaterials.push({
                    article: rawMaterial.article ?? undefined,
                    articleBatch: rawMaterial.articleBatch ?? undefined,
                    name: rawMaterial.article?.name ?? rawMaterial.articleBatch!.article!.name,
                    measurementUnit: rawMaterial.article?.measurementUnit.abbreviation ?? rawMaterial.articleBatch!.article!.measurementUnit.abbreviation,
                    quantity: rawMaterial.quantity
                });
            });

            this.isLoading = false;
        }
    }

    async ngOnInit() {
        await this.articleService.setupWebSocket();
        this.articleService.articles$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articles) => {
                    this.articles = articles;
                },
                error: () => {
                    this.toast.error('No se han podido cargar los artículos.');
                }
            });

        await this.articleBatchService.setupWebSocket();
        this.articleBatchService.articleBatches$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articleBatches) => {
                    this.articleBatches = articleBatches;
                },
                error: () => {
                    this.toast.error('No se han podido cargar los lotes de artículos.');
                }
            });


        this.loadBatchesOfArticlesInRawMaterials();
    }

    async ngAfterViewInit(): Promise<void> {
        setTimeout(() => {
            this.tableHeight =
                (this._modalContent.nativeElement as HTMLImageElement)
                    .clientHeight-29; // 29 is the size of the title
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next({});
        this.unsubscribe$.complete();
        this.articleService.disconnectWebSocket();
        this.articleBatchService.disconnectWebSocket();
    }

    compareById(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    compareByBatchNumber = (o1: ArticleBatchDto, o2: ArticleBatchDto): boolean => {
        return o1 && o2 ? o1.batchNumber === o2.batchNumber : o1 === o2;
    };

    compareByPackagingElementId = (o1: any, o2: any): boolean => {
        return o1 && o2 ? o1.packagingElementId === o2.packagingElementId : o1 === o2;
    };

    onArticleChanged(articleSelected: ArticleDto, index: number) {
        this.plainRawMaterials[index].articleBatch = undefined;
        this.plainRawMaterials[index].packaging = undefined;
        this.plainRawMaterials[index].quantity = 0;
        this.plainRawMaterials[index].measurementUnit = articleSelected.measurementUnit.abbreviation;

        if (articleSelected.useBatches) {
            const batches = this.articleBatches.filter(batch =>
                batch.articleId === articleSelected.id
            );
            this.batchesOfArticlesInRawMaterials.set(index, batches);
/*             this.plainRawMaterials[index].articleBatch = this.batchesOfArticlesInRawMaterials.get(index)![0];
 */            this.onBatchChanged(this.plainRawMaterials[index].articleBatch, index);

        } else {
            this.plainRawMaterials[index].articleBatch = undefined;
            this.batchesOfArticlesInRawMaterials.set(index, []);
        }

    }

    filterArticlesSelector(searchValue: string, article: any): boolean {
        if (!searchValue) {
            return true;
        }
        const lowerCaseSearch = searchValue.toLowerCase();
        return (
            article.nzValue.name?.toLowerCase().includes(lowerCaseSearch) ||
            article.nzValue.externalId?.toString().toLowerCase().includes(lowerCaseSearch) ||
            article.nzValue.ean?.toLowerCase().includes(lowerCaseSearch)
        );
    }

    filterArticleBatchesSelector = (searchValue: string, batch: any): boolean => {
        if (!searchValue) {
            return true;
        }
        const lowerCaseSearch = searchValue.toLowerCase();
        return (
            batch.nzValue.batchNumber?.toString().toLowerCase().includes(lowerCaseSearch) ||
            batch.nzValue.externalId?.toString().toLowerCase().includes(lowerCaseSearch)
        );
    };

    filterPackagingOnArticleBatchSelector = (searchValue: string, packaging: any): boolean => {
        if (!searchValue) {
            return true;
        }
        const lowerCaseSearch = searchValue.toLowerCase();
        return (
            packaging.nzValue.packagingBatchExternalId?.toString().toLowerCase().includes(lowerCaseSearch) ||
            packaging.nzValue.externalBatchNumber?.toLowerCase().includes(lowerCaseSearch)
        );
    };

    onBatchChanged(articleBatch: ArticleBatchDto | null = null, index: number) {
        this.plainRawMaterials[index].packaging = undefined;
        this.plainRawMaterials[index].quantity = 0;

        if (articleBatch?.packagings && articleBatch.packagings.length > 0) {
            this.packagingsOfBatchSelected.set(index, articleBatch.packagings);
            this.plainRawMaterials[index].packaging = [];
        } else {
            this.packagingsOfBatchSelected.set(index, []);
        }
    }

    handleOk() {
        const rawMaterialsToUpdate: RawMaterialDto[] = [];
        this.plainRawMaterials.forEach(plainRawMaterial => {
            rawMaterialsToUpdate.push({
                article: plainRawMaterial.article ?? undefined,
                articleBatch: plainRawMaterial.articleBatch ?? undefined,
                quantity: plainRawMaterial.quantity
            });
        });

        if (!this.validateRawMaterials(rawMaterialsToUpdate)) {
            return;
        }

        this.emitService.next(rawMaterialsToUpdate);
        this.modalService.closeAll();
    }

    deleteRawMaterial(idx: number) {
        this.plainRawMaterials.splice(idx, 1);
        this.plainRawMaterials = [...this.plainRawMaterials];
    }

    handleAddRawMaterial() {
        this.plainRawMaterials = [...this.plainRawMaterials, { quantity: 0 } as PlainRawMaterial];
    }

    handleCancel() {
        this.modalService.closeAll();
    }

    onArticleBatchSearch(value: string, rawMaterialIndex: number): void {
        this.articleBatchNumberSearchValue = value != '' ? value : this.articleBatchNumberSearchValue;

        if(value == '' && this.articleBatchNumberSearchValue !== '') {
            const searchTerm = this.articleBatchNumberSearchValue.trim();

            this.plainRawMaterials[rawMaterialIndex].articleBatch = this.articleBatches.find(batch => batch.batchNumber === searchTerm);

            this.articleBatchNumberSearchValue = '';
        }
    }

    private loadBatchesOfArticlesInRawMaterials() {
        this.rawMaterials.forEach((rawMaterial, index) => {
            const batches = this.articleBatches.filter(
                (batch) => batch.articleId === rawMaterial.articleId || batch.articleId === rawMaterial.article!.id || batch.articleId === rawMaterial.articleBatch?.articleId,
            );

            this.batchesOfArticlesInRawMaterials.set(index, batches);
        });
    }

    private validateRawMaterials(rawMaterialsToUpdate: RawMaterialDto[]): boolean {

        if (rawMaterialsToUpdate.length === 0) {
            this.toast.error('No se han seleccionado materias primas.');
            return false;
        }

        let isValid: boolean = true;

        this.plainRawMaterials.forEach((plainRawMaterial) => {

            if (!plainRawMaterial.article) {
                this.toast.error('No hay artículo asignado.');
                isValid = false;
            }

        });

        return isValid;
    }
}
