<form
  nz-form
  [nzLayout]="'vertical'"
  [formGroup]="dynamicForm"
  (ngSubmit)="onSubmit()"
  >
  @for (control of this.formStructure(); track $index) {
    @if (control["visible"] === true) {
      <nz-form-item class="pb-4">
        <nz-form-label [nzRequired]="control.required">{{
          control.label
        }}</nz-form-label>
        <nz-form-control [nzErrorTip]="getErrorMessage(control)">
          @if (
            control.component === "input" &&
            control.type === "string"
            ) {
            <input
              nz-input
              [type]="control.type"
              [formControlName]="control.name"
              />
          }
          @if (control.component === "inputnumber") {
            <nz-input-number [formControlName]="control.name" />
          } @else if (control.component === "date") {
            <nz-date-picker
              nzFormat="dd/MM/yyyy"
              [formControlName]="control.name"
            ></nz-date-picker>
          } @else if (control.component === "checkbox") {
            <label
              nz-checkbox
              [formControlName]="control.name"
            ></label>
          } @else if (
            control.component === "select" &&
            control.type === "list"
            ) {
            <nz-select
              class="pepito"
              nzAllowClear
              [nzMode]="compareTypeSelect(control.subtype)"
              [ngModel]="selectedTypeSelect(control)"
              [compareWith]="compareFn"
              [formControlName]="control.name"
              >
              @for (
                option of filterItemsOfType(
                control.name
                )
                ; track
                option) {
                <nz-option
                  [nzLabel]="option.name"
                  [nzValue]="option"
                ></nz-option>
              }
            </nz-select>
          }
        </nz-form-control>
      </nz-form-item>
    }
  }
</form>
