import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NZ_MODAL_DATA, NzModalService, NzModalTitleDirective, NzModalFooterDirective } from 'ng-zorro-antd/modal';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { ScrapDto } from 'src/app/shared/dto/scrap.dto';
import { NzTableComponent, NzTheadComponent, NzTrDirective, NzTableCellDirective, NzThMeasureDirective, NzTbodyComponent } from 'ng-zorro-antd/table';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-modal-watch-scraps',
    templateUrl: './modal-watch-scraps.component.html',
    styleUrls: ['./modal-watch-scraps.component.scss'],
    standalone: true,
    imports: [
        NzModalTitleDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        NzModalFooterDirective,
        NzEmptyModule,
        DatePipe
    ],
})
export class ModalWatchScrapsComponent {
    @ViewChild('modalContent') private readonly _modalContent!: ElementRef;

    isLoading: boolean = true;
    scraps: ScrapDto[] = [];
    article!: ArticleDto;
    processId: number = 0;
    processDescription: string = '';
    tableHeight!: number;

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private _modalService: NzModalService,
    ) {
        this.scraps = (data?.data?.scraps as ScrapDto[]) ?? [];
        this.article = (data?.data?.article as ArticleDto) ?? {};
        this.processId = data?.data?.processId as number;
        this.processDescription = data?.data?.processDescription as string;

        this.isLoading = false;
    }

    async ngAfterViewInit(): Promise<void> {
        setTimeout(() => {
            this.tableHeight =
                (this._modalContent.nativeElement as HTMLImageElement)
                    .clientHeight-29; // 29 is the size of the title
        });
    }

    handleCancel(): void {
        this._modalService.closeAll();
    }
}
