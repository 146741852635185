import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import io, { Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { SocketUris } from '../sockets/enums/socket-uris';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private socket: Socket = {} as Socket;

    constructor() {
        this.initializeSocketConnection();
    }

    conectSocketRoom(roomName: string) {
        this.socket.emit(SocketUris.JOIN_ROOM, roomName);
    }

    disconnectSocketRoom(roomName: string) {
        this.socket.emit(SocketUris.LEAVE_ROOM, roomName);
    }

    sendNotification(acction: number, roomName: string, message: string) {
        const notification = { acction: acction, room: roomName };
        this.socket.emit(message, notification);
    }

    getNotifications(message: string): Observable<any> {
        return new Observable((observer) => {
            this.socket.on(message, (data) => {
                observer.next(data);
            });

            return () => {
                this.socket.off(message);
            };
        });
    }

    private initializeSocketConnection(): void {
        const socketUrl: string = environment.socketUrl;

        console.log(socketUrl);

        this.socket = io(socketUrl, {
            reconnection: true,
            transports: ['websocket'],
            secure: true,
        });

        this.socket.on('connect', () => {
            console.log('Connected to socket');
        });

        this.socket.on('disconnect', (reason) => {
            console.log(`Disconnected from socket: ${reason}`);
        });
    }
}
